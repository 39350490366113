<template>
<CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Aangemaakte werkelijke tools - {{ machineNaam }}</strong
          >
          <CButton
            @click="goToWerkvloer"
            color="primary"
            class="btn-md"
            style="float: right"
          >
            Naar werkvloer
          </CButton>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            name="createdWerkelijkeTools"
            :metadata="metadata"
            :data="data"
            :rowClassRules="rowClassRules"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
            :multiSelect="true"
          />
        </CCardBody>
      </CCard>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
import CrudService from "@/services/CrudService.js";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";

export default {
  name: "TabletWerkelijkeToolsCreated",
  components: { DynamicGrid, CIcon },
  props: {
    data : {
        required: true
    }

  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { data: data } = toRefs(props);

    const metadata = computed(() => store.state.tabletmachinetoolmagazijnen.werkelijkeToolsMetadata);
    if (!store.state.dynamicgrid["createdWerkelijkeTools"]) {
      store.commit("dynamicgrid/SET_NEW_GRID", "createdWerkelijkeTools");
    }
    const machineNaam = computed(
      () => store.state.tabletmachinetoolmagazijnen.machineNaam
    );

    function goToWerkvloer() {
      store.commit("tabletmachinetoolmagazijnen/CLEAR_HISTORY");
      router.push({
        name: "werkvloer",
      });
    }

    return {
      metadata,
      data,
      machineNaam,
      goToWerkvloer,
      cilArrowLeft,
    };
  },
};
</script>
