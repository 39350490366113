<template>
  <div :style="active ? undefined : 'display:none'">
    <CModal size="xl" v-model:visible="active" @close="closeModal">
      <CModalHeader>
        <CModalTitle>{{ "Meerdere Locaties Toevoegen" }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow class="mb-3">
          <CCol :xs="12" :lg="6">
            <CRow class="mb-2">
              <CCol>Kast</CCol>
              <CCol>
                <select
                  class="form-control"
                  v-model="form.kast"
                  @input="onFieldValueChanged('kast', $event)"
                  @change="onFieldValueChanged('kast', $event)"
                >
                  <option value="" disabled>Kies een kast</option>
                  <option v-for="kast in kasten" :key="kast.id" :value="kast.id">
                    {{ kast.code }}
                  </option>
                </select>
                <div class="text-danger" v-if="errors.kast">{{ errors.kast }}</div>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol>Lade (optioneel)</CCol>
              <CCol>
                <select
                  class="form-control"
                  v-model="form.lade"
                  @input="onFieldValueChanged('lade', $event)"
                  @change="onFieldValueChanged('lade', $event)"
                >
                  <option value="" disabled>Kies een lade</option>
                  <option v-for="lade in lades" :key="lade.id" :value="lade.id">
                    {{ lade.code }}
                  </option>
                </select>
              </CCol>
            </CRow>
          </CCol>
          <CCol :xs="12" :lg="6">
            <CRow class="mb-2">
              <CCol>Nummer van</CCol>
              <CCol>
                <input
                  class="form-control"
                  type="number"
                  v-model.number="form.nummerVan"
                  @input="onFieldValueChanged('nummerVan', $event)"
                  @change="onFieldValueChanged('nummerVan', $event)"
                />
                <div class="text-danger" v-if="errors.nummerVan">
                  {{ errors.nummerVan }}
                </div>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol>Nummer t/m</CCol>
              <CCol>
                <input
                  class="form-control"
                  type="number"
                  v-model.number="form.nummerTot"
                  @input="onFieldValueChanged('nummerTot', $event)"
                  @change="onFieldValueChanged('nummerTot', $event)"
                />
                <div class="text-danger" v-if="errors.nummerTot">
                  {{ errors.nummerTot }}
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CCol :xs="12" :lg="6">
            <CRow class="mb-2">
              <CCol>Aantal cijfers</CCol>
              <CCol>
                <input
                  class="form-control"
                  type="number"
                  v-model.number="form.aantalCijfers"
                  @input="onFieldValueChanged('aantalCijfers', $event)"
                  @change="onFieldValueChanged('aantalCijfers', $event)"
                  placeholder="Bijv. 3"
                />
                <div class="text-danger" v-if="errors.aantalCijfers">
                  {{ errors.aantalCijfers }}
                </div>
              </CCol>
            </CRow>
          </CCol>
          <CCol :xs="12" :lg="6">
            <CRow>
              <CCol>Even nummers aanmaken</CCol>
              <CCol>
                <CFormCheck type="checkbox" v-model="form.evenNummers" />
              </CCol>
            </CRow>
            <CRow>
              <CCol>Oneven nummers aanmaken</CCol>
              <CCol>
                <CFormCheck type="checkbox" v-model="form.onevenNummers" />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="closeModal">Annuleren</CButton>
        <CButton color="primary" @click="createLocations" :disabled="hasErrors">
          Toevoegen
        </CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import { defineComponent, ref, toRefs, computed, onMounted, watch } from "vue";
import CrudService from "@/services/CrudService";

export default defineComponent({
  name: "AddMultipleLocationsModal",
  props: {
    modalActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["onContinue", "onCancel"],
  setup(props, { emit }) {
    const { modalActive: active } = toRefs(props);

    const form = ref({
      kast: "",
      lade: "",
      nummerVan: 1,
      nummerTot: 10,
      aantalCijfers: 3,
      evenNummers: true,
      onevenNummers: true,
    });

    const kasten = ref([]);
    const lades = ref([]);
    const errors = ref({});

    const fetchLades = async (kastId) => {
      if (!kastId) {
        lades.value = [];
        return;
      }
      try {
        const ladesData = await CrudService.getGridData(`lades/byKast/${kastId}`);
        lades.value = ladesData.data.value;
      } catch (error) {
        console.error("Error fetching lades: ", error);
      }
    };

    const fetchKasten = async () => {
      try {
        const kastenData = await CrudService.getGridData("kasten");
        kasten.value = kastenData.data.value;
      } catch (error) {
        console.error("Error fetching kasten: ", error);
      }
    };

    onMounted(() => {
      fetchKasten();
    });

    watch(
      () => form.value.kast,
      (newKast) => {
        fetchLades(newKast);
      }
    );

    const validateForm = () => {
      errors.value = {};
      if (!form.value.kast) errors.value.kast = "Kast is verplicht.";

      if (parseInt(form.value.nummerVan) > parseInt(form.value.nummerTot))
        errors.value.nummerVan = "Nummer van moet kleiner zijn dan nummer t/m.";
      if (parseInt(form.value.aantalCijfers) < 1)
        errors.value.aantalCijfers = "Aantal cijfers moet groter zijn dan 0.";
    };

    const hasErrors = computed(() => Object.keys(errors.value).length > 0);

    function closeModal() {
      emit("onCancel");
    }

    function onFieldValueChanged(property, event) {
      form.value[property] = event.target.value;
      validateForm();
    }

    function createLocations() {
      validateForm();
      if (hasErrors.value) return;

      const {
        kast,
        lade,
        nummerVan,
        nummerTot,
        aantalCijfers,
        evenNummers,
        onevenNummers,
      } = form.value;

      const selectedKast = kasten.value.find((k) => k.id == kast);
      const selectedLade = lades.value.find((l) => l.id == lade);

      const locations = [];

      for (let i = nummerVan; i <= nummerTot; i++) {
        if ((evenNummers && i % 2 === 0) || (onevenNummers && i % 2 !== 0)) {
          const nummer = i.toString().padStart(aantalCijfers, "0");
          const kastCode = selectedKast ? selectedKast.code : "";
          const ladeCode = selectedLade ? selectedLade.code : "";
          const code = lade
            ? `${kastCode}-${ladeCode}-${nummer}`
            : `${kastCode}-${nummer}`;

          locations.push({
            kastId: kast,
            ladeId: lade,
            code,
          });
        }
      }

      emit("onContinue", locations);
    }

    validateForm();

    return {
      form,
      kasten,
      lades,
      errors,
      closeModal,
      createLocations,
      active,
      hasErrors,
      onFieldValueChanged,
    };
  },
});
</script>
