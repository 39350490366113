import CrudService from "../../services/CrudService";
import { loadOptionsAsync, prepareData } from "../../helpers/apiHelper";

const namespaced = true;

const state = {
  toolsMetadata: {},
  componentMetadata: {},
  componentsMetadata: {},
  werkelijkeToolsMetadata: {},
  werkelijkeToolsFormMetadata: {},
  werkelijkeTools: [],
  toolsData: {
  },

  componentsData: [],
  oldComponentsInTool: [],
  currentComponentsInTool: [],
  machines: [],
};

const mutations = {
  SET_COMPONENT_METADATA(state, payload) {
    state.componentMetadata = payload;
  },

  SET_COMPONENTS_METADATA(state, payload) {
    state.componentsMetadata = payload;
  },

  SET_COMPONENTS_DATA(state, { payload }) {
    state.componentsData = payload;
  },

  SET_TOOLS_METADATA(state, payload) {
    state.toolsMetadata = payload;
  },

  SET_TOOLS_DATA(state, { payload }) {
    state.toolsData = payload;
  },

  SET_WERKELIJKE_TOOLS_METADATA(state, payload) {
    payload.properties.push({
      variableName: "printLabel",
      displayName: "Print",
      pinned: true,
      displayType: 10,
    });
    state.werkelijkeToolsMetadata = payload;
  },

  SET_WERKELIJKE_TOOLS_FORM_METADATA(state, payload) {
    state.werkelijkeToolsFormMetadata = payload;
  },

  SET_OLD_COMPONENTS_IN_TOOL(state, { payload }) {
    state.oldComponentsInTool = payload;
  },

  SET_CURRENT_COMPONENTS_IN_TOOL(state, { payload }) {
    state.currentComponentsInTool = payload;
  },

  SET_WERKELIJKE_TOOLS(state, { payload }) {
    state.werkelijkeTools = payload;
  },

  SET_MACHINES(state, { payload }) {
    state.machines = payload;
  },

  SET_NEW_TOOLS_FORM(state) {
    state.toolsData = {
      werkelijkeTools: [],
      components: [],
    };
    state.toolsMetadata = [];
    state.componentsData = [];
    state.oldComponentsInTool = [];
    state.currentComponentsInTool = [];
    state.machines = [];
  },
};

const actions = {
  getToolsMetadata({ commit }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "toolsmetadata" },
      { root: true }
    );
    CrudService.getMetadataByName("tools", "form")
      .then((response) => {
        commit("SET_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "toolsmetadata" },
          { root: true }
        )
      );
  },

  getComponentsMetadata({ commit }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "componentsMetadata" },
      { root: true }
    );

    CrudService.getMetadataByName("components", "grid")
      .then((response) => {
        commit("SET_COMPONENTS_METADATA", response.data);
        const props = JSON.parse(JSON.stringify(response.data));
        props.properties.push({
          variableName: "aantal",
          displayName: "Aantal",
          displayType: 1,
          pinned: true,
          editable: true,
        });
        commit("SET_COMPONENT_METADATA", props);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "componentsMetadata" },
          { root: true }
        )
      );
  },

  getWerkelijkeToolsMetadata({ commit }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "werkelijkeToolsMetadata" },
      { root: true }
    );

    CrudService.getMetadataByName("werkelijketools", "grid")
      .then((response) => {
        response.data.properties = response.data.properties.filter(
          (p) => p.variableName != "tool"
        );
        commit("SET_WERKELIJKE_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "werkelijkeToolsMetadata" },
          { root: true }
        )
      );
  },

  getWerkelijkeTools({ commit }, { toolId }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "toolWerkelijketools" },
      { root: true }
    );

    CrudService.getGridData(`werkelijketools/byTool/${toolId}`)
      .then((response) => {
        prepareData(response.data.value);
        commit("SET_WERKELIJKE_TOOLS", { payload: response.data.value });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "toolWerkelijketools" },
          { root: true }
        )
      );
  },

  getToolData({ commit }, { id, metadata }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "toolsData" },
      { root: true }
    );

    // First replace is ugly, is because machineGroep is still handled in old wrong way. Needs refactor.
    CrudService.postGraphQlQuery(metadata.graphQlDataRequestBody.replace('tools { id camToolnummer toolCode }', 'tools { id camToolnummer toolCode machineGroepId }').replace('$id', id.toString()))
      .then(async (response) => {
        response.data.data.tool.machineGroepId = response.data.data.tool.machineGroep.id;
        prepareData(response.data.data.tool);
        await loadOptionsAsync(
          metadata.properties,
          undefined,
          id.value,
          'tools',
          response.data.data
        );

        const hasToollijstToolOrWerkelijkeTool =
          await CrudService.getDataByQuery("tools/restrictedit", "toolId", id);
          response.data.data.tool.restrictEdit =
          hasToollijstToolOrWerkelijkeTool.data.success &&
          hasToollijstToolOrWerkelijkeTool.data.value;

        commit("SET_TOOLS_DATA", { payload: response.data.data.tool });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "toolsData" },
          { root: true }
        )
      );
  },

  getRequiredData({ commit }, { metadata }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "requiredToolsData" },
      { root: true }
    );

    // Replace is ugly, is because machineGroep is still handled in old wrong way. Needs refactor.
    CrudService.postGraphQlQuery(metadata.graphQlRequiredDataRequestBody.replace('tools { id camToolnummer toolCode }', 'tools { id camToolnummer toolCode machineGroepId }'))
      .then(async (response) => {
        await loadOptionsAsync(
          metadata.properties,
          undefined,
          undefined,
          'tools',
          response.data.data
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "requiredToolsData" },
          { root: true }
        )
      );
  },

  getComponentsByToolId({ commit }, toolId) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "componentsByToolMetadata" },
      { root: true }
    );

    CrudService.getGridData(`components/byTool/${toolId}`)
      .then((response) => {
        prepareData(response.data.value);
        commit("SET_CURRENT_COMPONENTS_IN_TOOL", { payload: response.data.value });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "componentsByToolMetadata" },
          { root: true }
        )
      );
  },

  getComponentsData({ commit }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "componentsMetadata" },
      { root: true }
    );

    CrudService.getGridData("components")
      .then((response) => {
        prepareData(response.data.value);
        commit("SET_COMPONENTS_DATA", { payload: response.data.value });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "componentsMetadata" },
          { root: true }
        )
      );
  },

  getWerkelijkeToolsFormMetadata({ commit }, { id, machineGroep }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "werkelijketoolsformmetadata" },
      { root: true }
    );


    CrudService.getMetadataByName("werkelijketools", "form")
      .then(async (response) => {
        response.data.properties = response.data.properties.filter(
          (p) => p.editable || !!id || p.variableName === "toolId"
        );

        let extraData = [];
        for (const property of response.data.properties) {
          if(property.variableName === 'registerRegel') continue;
          if (property.endpoint) {
            let filter = extraData.filter(
              (d) => d.endpoint === property.endpoint
            );
            property.endpoint = id
              ? property.endpoint.replace("{id}", id)
              : property.endpoint.replace("&includeId={id}", "");
            let data;

            if (property.endpoint == "tools") {
              data = filter?.length
                ? filter[0].data
                : (await CrudService.getGridData(property.endpoint)).data.value;
            } else {
              data = filter?.length
                ? filter[0].data
                : (
                    await CrudService.getDataByQuery(
                      property.endpoint,
                      "machineGroep",
                      machineGroep.naam
                    )
                  ).data.value;
            }

            if (!filter?.length) {
              extraData.push({ endpoint: property.endpoint, data });
            }

            property.usedValues = data;
            if (property.displayProperties?.length) {
              property.options = data.map((obj) => {
                let values = [];
                property.displayProperties.forEach((element) => {
                  const split = element.split(".");
                  let actualPath = obj;
                  split.forEach((el) => {
                    if (actualPath[el] === undefined || actualPath[el] === null)
                      return;
                    actualPath = actualPath[el];
                  });
                  values.push(actualPath);
                });
                return {
                  displayText: values.join("-"),
                  key: obj[property.keyProperty],
                };
              });
            }
          }
        }

        console.log(response.data)
        commit("SET_WERKELIJKE_TOOLS_FORM_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() => {
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "werkelijketoolsformmetadata" },
          { root: true }
        );
      });
  },

  printLabels({ commit }, werkelijkeTools) {
    CrudService.postData(
      "werkelijketools/printlabels",
      werkelijkeTools.map((x) => x.id)
    )
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "De labels worden geprint.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachines({ commit }, { payload }) {
    CrudService.getDataByQuery("machines", "machineGroep", payload)
      .then((response) => {
        commit("SET_MACHINES", { payload: response.data.value });
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const toolscrud = {
  state,
  mutations,
  actions,
  namespaced,
};

export default toolscrud;
