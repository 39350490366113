<template>
  <v-select
    :options="paginated"
    v-if="getDisplayType(property) === 'dropdown'"
    :model-value="modelValue"
    @update:model-value="handleValueChange"
    :disabled="shouldBeDisabled(property) ? 'disabled' : undefined"
    :reduce="(option) => option.key"
    label="displayText"
    placeholder="Maak uw keuze"
    class="dropdown"
    @search="onSearch"
  >
    <template #list-footer>
      <li class="pagination">
        <button
          v-if="hasPrevPage || hasNextPage"
          :disabled="!hasPrevPage"
          @click="decrementOffset"
        >
          Vorige
        </button>
        <button
          v-if="hasPrevPage || hasNextPage"
          :disabled="!hasNextPage"
          @click="incrementOffset"
        >
          Volgende
        </button>
      </li>
    </template>
    <template #no-options> Geen opties beschikbaar. </template>
  </v-select>
</template>

<script>
import { ref, computed, toRefs, watch } from "vue";

export default {
  props: {
    modelValue: {
      required: false,
    },
    property: {
      required: false,
    },
    asyncOptions: {
      required: false,
    },
    getDisplayType: {
      required: false,
    },
    onFieldValueChanged: {
      required: false,
    },
    shouldBeDisabled: {
      required: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue: modelValue } = toRefs(props);
    const search = ref("");
    const offset = ref(0);
    const limit = ref(9);


    const computedOptions = computed(() => {
      if (
        props.property.dynamicOptions &&
        props.asyncOptions[props.property.variableName]
      ) {
        return props.asyncOptions[props.property.variableName];
      }
      return props.property.options || [];
    });

    watch(modelValue, () => {

    setTimeout(() => {
      if (modelValue.value) {
        const page = getPageForId(modelValue.value);
        offset.value = page * limit.value;
      }
    }, 0);
    }, { immediate: true });

    function getPageForId(id) {
      const index = computedOptions.value.findIndex(
        (option) => option.key == id
      );

      return Math.floor(index / limit.value);
    }
    const filtered = computed(() => {
      return computedOptions.value.filter((option) =>
        option.displayText
          .toLocaleLowerCase()
          .includes(search.value.toLocaleLowerCase())
      );
    });
    const paginated = computed(() => {
      let filteredData = filtered.value.slice(
        offset.value,
        limit.value + offset.value
      );

      const selectedItem = computedOptions.value.find((x) => x.key == modelValue.value);
      
      if (modelValue.value && selectedItem && !filteredData.some((x) => x.key == modelValue.value)) {
        filteredData.unshift(selectedItem);
      }
      
      return filteredData;
    });


    const hasNextPage = computed(() => {
      return offset.value + limit.value < filtered.value.length;
    });

    const hasPrevPage = computed(() => {
      return offset.value > 0;
    });

    function handleValueChange(value) {
      emit("update:modelValue", value); // emit the update:modelValue event
      if (props.onFieldValueChanged) {
        props.onFieldValueChanged(props.property, { target: { value } });
      }
    }

    function incrementOffset() {
      offset.value += limit.value;
    }

    function decrementOffset() {
      offset.value -= limit.value;
    }

    function onSearch(query) {
      search.value = query;
      offset.value = 0;
    }

    return {
      paginated,
      handleValueChange,
      hasPrevPage,
      hasNextPage,
      incrementOffset,
      decrementOffset,
      computedOptions,
      filtered,
      limit,
      search,
      offset,
      onSearch,
    };
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>
