import CrudService from "../../services/CrudService";

const namespaced = true;

const state = {
  toolmagazijnenData: [],
  toolmagazijnenMetadata: {},
  machineId: undefined,
  machineNaam: "",
  werkelijkeToolsMetadata: {},
};

const mutations = {
  SET_TOOL_MAGAZIJNEN_METADATA(state, payload) {
    state.toolmagazijnenMetadata = payload;
  },
  SET_WERKELIJKE_TOOLS_METADATA(state, payload) {
    state.werkelijkeToolsMetadata = payload;
  },
  SET_TOOL_MAGAZIJNEN_DATA(state, payload) {
    state.toolmagazijnenData = payload;
  },

  SET_MACHINE_ID(state, payload) {
    state.machineId = payload;
  },

  SET_MACHINE_NAME(state, payload) {
    state.machineNaam = payload;
  },

  CLEAR_HISTORY(state) {
    state.toolmagazijnenData = [];
    state.machineNaam = "";
  },
};

const actions = {
  getToolmagazijnenMetadata({ commit }) {
    CrudService.getMetadataByName("toolmagazijnen", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "bijwerken",
          displayName: "Bijwerken",
          displayType: 3,
          multiSelect: true,
          selectAll: true,
          disabledConditions: [
            {
              propertyName: "isCorrect",
              equals: true,
            },
          ],
        });
        commit("SET_TOOL_MAGAZIJNEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
  getWerkelijkeToolsMetadata({ commit }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "werkelijkeToolsMetadata" },
      { root: true }
    );

    CrudService.getMetadataByName("werkelijketools", "grid")
      .then((response) => {
        response.data.properties = response.data.properties.filter(
          (p) => p.variableName != "tool"
        );
        commit("SET_WERKELIJKE_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "werkelijkeToolsMetadata" },
          { root: true }
        )
      );
  },
  getToolmagazijnenData({ commit }, machineId) {
    commit("SET_LOADING_STATUS", true, { root: true });
    CrudService.getDataById("ToolmagazijnImport", machineId)
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOL_MAGAZIJNEN_DATA", response.data.value.mappedTools.map(x=>
            {
              return x;
            }));
          commit("SET_MACHINE_NAME", response.data.value.machineNaam);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() => commit("SET_LOADING_STATUS", false, { root: true }));
  },
};

const tabletmachinetoolmagazijnen = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletmachinetoolmagazijnen;
