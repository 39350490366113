import CrudService from "../../services/CrudService";
import ToolService from "../../services/ToolService";
import DownloadClient from "../../services/DownloadClient";

const namespaced = true;

const state = {
  toolsData: [],
  toolsSelected: [],
  toolsMetadata: {},
  componentMetadata: {},
  componentData: [],
  werkelijkeToolsMetadata: {},
  werkelijkeToolsData: [],
  machineGroepen: [],
  lastImage: {},
  machineGroepSelected: null,
  werkelijkeToolModalMetadata: {},
  werkelijkeToolModalData: [],
  componentModalMetadata: {},
  componentModalData: [],
  files: []
};

const mutations = {
  SET_TOOLS_DATA(state, payload) {
    state.toolsData = payload;
  },

  SET_TOOLS_SELECTED(state, payload) {
    state.toolsSelected = payload;
  },

  SET_TOOLS_METADATA(state, payload) {
    state.toolsMetadata = payload;
  },

  SET_COMPONENT_METADATA(state, payload) {
    state.componentMetadata = payload;
  },

  SET_COMPONENT_DATA(state, payload) {
    state.componentData = payload;
  },

  SET_FILES(state, payload) {
    state.files = payload;
  },

  SET_WERKELIJKE_TOOLS_METADATA(state, payload) {
    state.werkelijkeToolsMetadata = payload;
  },

  SET_WERKELIJKE_TOOLS_DATA(state, payload) {
    state.werkelijkeToolsData = payload;
  },

  SET_WERKELIJKE_TOOL_MODAL_METADATA(state, payload) {
    state.werkelijkeToolModalMetadata = payload;
  },

  SET_WERKELIJKE_TOOL_MODAL_DATA(state, payload) {
    state.werkelijkeToolModalData = payload;
  },

  SET_COMPONENT_MODAL_METADATA(state, payload) {
    state.componentModalMetadata = payload;
  },

  SET_COMPONENT_MODAL_DATA(state, payload) {
    state.componentModalData = payload;
  },

  SET_MACHINE_GROEPEN(state, payload) {
    state.machineGroepen = payload;
  },

  REMOVE_ROW(state, id) {
    const index = state.toolsData.findIndex((tool) => tool.id === id);
    state.toolsData.splice(index, 1);
  },

  SET_LAST_IMAGE(state, payload) {
    state.lastImage = payload;
  },

  SET_MACHINE_GROEP_SELECTED(state, payload) {
    state.machineGroepSelected = payload;
  },

  CLEAR_HISTORY(state) {
    state.toolsData = [];
    state.componentData = [];
    state.werkelijkeToolsData = [];
    state.machineGroepen = [];
    state.lastImage = [];
  },
};

const actions = {
  getComponentsMetadata({ commit }) {
    CrudService.getMetadataByName("components", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "aantal",
          displayName: "Aantal",
          displayType: 1,
          pinned: true,
        });
        commit("SET_COMPONENT_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getWerkelijkeToolsMetadata({ commit }) {
    CrudService.getMetadataByName("werkelijkeTools", "grid")
      .then((response) => {
        commit("SET_WERKELIJKE_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsData({ commit }, metadata) {
    CrudService.postGraphQlQuery(metadata.graphQlDataRequestBody)
      .then((response) => {
        commit("SET_TOOLS_DATA", response.data.data.tools);
        commit("SET_TOOLS_SELECTED", response.data.data.tools);
      })
      .catch((error) => {
        commit("ADD_TOAST_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsMetadata({ commit }) {
    CrudService.getMetadataByName("tools", "grid")
      .then((response) => {
        commit("SET_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachineGroepen({ commit }) {
    CrudService.getGridData("machinegroepen")
      .then((response) => {
        if (response.data.success) {
          commit(
            "SET_MACHINE_GROEPEN",
            response.data.value.map((obj) => obj.naam).sort()
          );
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  deleteTool({ commit }, id) {
    ToolService.deleteTool(id)
      .then((response) => {
        if (response.data.success) {
          commit("REMOVE_ROW", id);
          commit(
            "ADD_TOAST_SUCCESS",
            "De geselecteerde tool is succesvol verwijderd.",
            { root: true }
          );
          commit("SET_COMPONENT_DATA", []);
          commit("SET_WERKELIJKE_TOOLS_DATA", []);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("ADD_TOAST_ERROR", error, { root: true });
        throw error;
      });
  },

  downloadImage({ commit }, { toolId, resourceName, tryDownloadThumbnail  }) {
    DownloadClient()
      .get(`files?toolId=${toolId}&resource=${resourceName}&tryDownloadThumbnail=${tryDownloadThumbnail}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        commit(
          "SET_LAST_IMAGE",
          "data:image/.jpeg;base64," +
            Buffer.from(response.data, "binary").toString("base64")
        );
      });
  },
  getComponents({ commit }, toolId) {
    CrudService.getGridData(`components/byTool/${toolId}`)
      .then((response) => {
        if (response.data.success) {
          commit("SET_COMPONENT_DATA", response.data.value);
        } else {
          commit("SET_COMPONENT_DATA", []);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getWerkelijkeTools({ commit }, toolId) {
    CrudService.getGridData(`werkelijketools/byTool/${toolId}`)
      .then((response) => {
        if (response.data.success) {
          commit("SET_WERKELIJKE_TOOLS_DATA", response.data.value);
        } else {
          commit("SET_WERKELIJKE_TOOLS_DATA", []);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getWerkelijkeToolModalMetadata({ commit }) {
    CrudService.getMetadataByName("werkelijketools", "modal")
      .then((response) => {
        commit("SET_WERKELIJKE_TOOL_MODAL_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getComponentModalMetadata({ commit }) {
    CrudService.getMetadataByName("components", "modal")
      .then((response) => {
        commit("SET_COMPONENT_MODAL_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const toolsview = {
  state,
  mutations,
  actions,
  namespaced,
};

export default toolsview;
