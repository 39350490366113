<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img :src="logo" class="sidebar-brand-full mt-2 mb-2" height="70"/>
    </CSidebarBrand>
    <AppSidebarNav/>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import {AppSidebarNav} from "./AppSidebarNav";

export default {
  name: "AppSidebar",
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore();
    const logo = computed(() => store.state.logo);
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      logo
    };
  },
};
</script>

<style scoped>
.sidebar {
  /* Change from blue to a different color, for example: */
  background-color: #ffffff; /* white */
  /* or */
  background-color: #f5f5f5; /* light gray */
}
</style>
