import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout";
import Wissellijst from "@/views/wissellijsten/Wissellijsten.vue";
import DynamicFormView from "@/views/dynamicform/DynamicFormView.vue";
import DynamicGridView from "@/views/dynamicgrid/DynamicGridView.vue";
import MachinesFormView from "@/views/machines/MachineFormView.vue";
import ToolsView from "@/views/tools/ToolsView.vue";
import PrintersView from "@/views/printers/PrintersView.vue";
import LocatiesView from "@/views/locaties/LocatiesView.vue";
import WerkelijkeToolsView from "@/views/werkelijketools/WerkelijkeToolsView.vue";
import ToolsCrud from "@/views/tools/ToolsCrud.vue";
import AddComponentsToTool from "@/views/tools/AddComponentsToTool.vue";
import ComponentsView from "@/views/components/ComponentsView.vue";
import ComponentsCrud from "@/views/components/ComponentsCrud";
import ToollijstenView from "@/views/toollijsten/ToollijstenView";
import ToollijstenCrud from "@/views/toollijsten/ToollijstenCrud";
import WissellijstenView from "@/views/wissellijstenview/WissellijstenView";
import VoorinstelOrders from "@/views/voorinstelorders/VoorinstelOrders";
import TabletMachinesView from "@/views/werkvloer/TabletMachinesView";
import TabletMachineToollijsten from "@/views/werkvloer/TabletMachineToollijsten";
import TabletMachineWissellijst from "@/views/werkvloer/TabletMachineWissellijsten";
import TabletToollijstTools from "@/views/werkvloer/TabletToollijstTools";
import TabletMachineMagazijnen from "@/views/werkvloer/TabletMachineMagazijnen";
import TabletVersletenTools from "@/views/werkvloer/TabletVersletenTools";
import WerkelijkeToolsCrud from "@/views/tools/WerkelijkeToolsCrud";
import TabletMachineWissellijstenHistory from "@/views/werkvloer/TabletMachineWissellijstenHistory";
import TabletWissellijstenHistoryRegels from "@/views/werkvloer/TabletWissellijstenHistoryRegels";
import TabletVersletenHistory from "@/views/werkvloer/TabletVersletenHistory";
import TabletVervangingenHistory from "@/views/werkvloer/TabletVervangingenHistory";
import TabletMachineToolmagazijnImport from "@/views/werkvloer/TabletMachineToolmagazijnImport";
import OrdersCrud from "@/views/orders/OrdersCrud";
import OpspanningenCrud from "@/views/orders/OpspanningenCrud";
import TabletMachineToolregisterImport from "@/views/werkvloer/TabletMachineToolregisterImport";
import ToolHistoryView from "@/views/toolhistory/ToolHistoryView";
import ToolToollijstenView from "@/views/tooltoollijsten/ToolToollijstenView";

const routes = [
  {
    path: "/",
    name: "Home",
    component: DefaultLayout,
    redirect: "/view/tools",
    children: [
      {
        path: "/wissellijst/:machineId",
        name: "wissellijsten",
        component: Wissellijst,
        props: true,
      },
      {
        path: "/form/:name/:id?",
        name: "dynamicformview",
        component: DynamicFormView,
        props: true,
      },
      {
        path: "/view/:name",
        name: "dynamicgridview",
        component: DynamicGridView,
        props: true,
      },
      {
        path: "/view/tools",
        name: "toolsview",
        component: ToolsView,
        props: true,
      },
      {
        path: "view/werkelijketools",
        name: "werkelijketoolsview",
        component: WerkelijkeToolsView,
        props: true,
      },
      {
        path: "/form/tools/:id?",
        name: "toolscrud",
        component: ToolsCrud,
        props: true,
      },
      {
        path: "/form/orders/:id?",
        name: "orderscrud",
        component: OrdersCrud,
        props: true,
      },
      {
        path: "form/orders/opspanningen/:id?",
        name: "opspanningencrud",
        component: OpspanningenCrud,
        props: true,
      },
      {
        path: "/view/components",
        name: "components",
        component: ComponentsView,
        props: true,
      },
      {
        path: "/form/components/:id?",
        name: "componentscrud",
        component: ComponentsCrud,
        props: true,
      },
      {
        path: "/form/machines/:id?",
        name: "machinesformview",
        component: MachinesFormView,
        props: true,
      },
      {
        path: "/view/toollijsten",
        name: "toollijsten",
        component: ToollijstenView,
        props: true,
      },
      {
        path: "/form/toollijsten/:id?",
        name: "toollijstencrud",
        component: ToollijstenCrud,
        props: true,
      },
      {
        path: "/view/wissellijsten",
        name: "wissellijstenview",
        component: WissellijstenView,
        props: true,
      },
      {
        path: "voorinstelorders",
        name: "voorinstelorders",
        component: VoorinstelOrders,
        props: true,
      },
      {
        path: "werkvloer",
        name: "werkvloer",
        component: TabletMachinesView,
        props: true,
      },
      {
        path: "werkvloer/machinetoollijsten/:machineId",
        name: "tabletmachinetoollijsten",
        component: TabletMachineToollijsten,
        props: true,
      },
      {
        path: "werkvloer/toolmagazijnimport/:machineId",
        name: "tabletmachinetoolmagazijnimport",
        component: TabletMachineToolmagazijnImport,
        props: true,
      },
      {
        path: "werkvloer/toolregisterimport/:machineId",
        name: "tabletmachinetoolregisterimport",
        component: TabletMachineToolregisterImport,
        props: true,
      },
      {
        path: "werkvloer/machinewissellijst/:wissellijstId",
        name: "tabletmachinewissellijst",
        component: TabletMachineWissellijst,
        props: true,
      },
      {
        path: "werkvloer/toollijsttools/:toollijstId/:machineId",
        name: "tablettoollijsttools",
        component: TabletToollijstTools,
        props: true,
      },
      {
        path: "werkvloer/machinemagazijnen/:machineId",
        name: "tabletmachinemagazijnen",
        component: TabletMachineMagazijnen,
        props: true,
      },
      {
        path: "werkvloer/machinemagazijnen/vervangingen/:machineId",
        name: "tabletversletentools",
        component: TabletVersletenTools,
        props: true,
      },
      {
        path: "form/tools/werkelijketools",
        name: "werkelijketoolscrud",
        component: WerkelijkeToolsCrud,
        props: true,
      },
      {
        path: "werkvloer/history/wissellijsten/:machineId",
        name: "tabletmachinewissellijstenhistory",
        component: TabletMachineWissellijstenHistory,
        props: true,
      },
      {
        path: "werkvloer/history/wissellijsten/:machineId/:wissellijstId",
        name: "tabletwissellijstenhistoryregels",
        component: TabletWissellijstenHistoryRegels,
        props: true,
      },
      {
        path: "werkvloer/history/vervangingen/:machineId",
        name: "tabletversletenhistory",
        component: TabletVersletenHistory,
        props: true,
      },
      {
        path: "werkvloer/history/vervangingen/:machineId",
        name: "tabletvervangingenhistory",
        component: TabletVervangingenHistory,
        props: true,
      },
      {
        path: "tools/history/:toolId",
        name: "toolhistory",
        component: ToolHistoryView,
        props: true,
      },
      {
        path: "tools/toollijsten/:toolId",
        name: "tooltoollijsten",
        component: ToolToollijstenView,
        props: true,
      },
      {
        path: "view/printers",
        name: "printers",
        component: PrintersView,
        props:true
      },
      {
        path: "view/locaties",
        name: "locaties",
        component: LocatiesView,
        props:true
      },
      {
        path: "/form/tools/:toolId?/components",
        name: "toolcomponents",
        component: AddComponentsToTool,
        props: true
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
