import CrudService from "../../services/CrudService";

const namespaced = true;

const state = {
  magazijnenData: [],
  magazijnenMetadata: {},
  versletenWerkelijkeToolIds: [],
  machineNaam: undefined,
};

const mutations = {
  SET_MAGAZIJNEN_METADATA(state, payload) {
    state.magazijnenMetadata = payload;
  },

  SET_MAGAZIJNEN_DATA(state, payload) {
    state.magazijnenData = payload;
  },

  SET_VERSLETEN_TOOL_IDS(state, payload) {
    state.versletenWerkelijkeToolIds = payload;
  },

  SET_MACHINE_NAAM(state, payload) {
    state.machineNaam = payload;
  },
};

const actions = {
  getMagazijnenMetadata({ commit }) {
    CrudService.getMetadataByName("machinemagazijnen", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "camToolnummer",
          displayName: "CAM Nr",
          displayType: 1,
          displayPropertiesPath: ["werkelijkeTool", "tool"],
          displayProperties: ["camToolnummer"],
        });
        response.data.properties.push({
          variableName: "exchange",
          displayName: "Wijzigen",
          displayType: 10,
          disabledConditions: [
            {
              propertyName: "versleten",
              equals: true,
            },
            {
              propertyName: "standaard",
              equals: true,
            },
            {
              propertyName: "opnieuwInlezen",
              equals: true
            }
          ],
        });
        response.data.properties.push({
          variableName: "versleten",
          displayName: "Versleten",
          displayType: 10,
          disabledConditions: [
            {
              propertyName: "werkelijkeToolBeschikbaar",
              equals: false,
            },
            {
              propertyName: "exchange",
              equals: true,
            },
          ],
        });
        commit("SET_MAGAZIJNEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMagazijnenData({ commit }, machineId) {
    CrudService.getDataById("machines", machineId)
      .then((response) => {
        if (response.data.success) {
          const date = new Date(response.data.value.leesdatum);
          const now = new Date();
          now.setHours(now.getHours() - 4);
          const opnieuwInlezen = !!response.data.value.toolmagazijnImportProcedureId && date < now;

          response.data.value.machineMagazijnen.map((m) => {
            m.werkelijkeToolBeschikbaar = !!m.werkelijkeTool;
            m.opnieuwInlezen = opnieuwInlezen;
            return m;
          });
          commit(
            "SET_MAGAZIJNEN_DATA",
            response.data.value.machineMagazijnen.map((m) => {
              m.exchange = false;
              return m;
            })
          );
          commit("SET_MACHINE_NAAM", response.data.value.naam);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tabletmachinemagazijnen = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletmachinemagazijnen;
