import CrudService from "../services/CrudService";
import moment from "moment";

export async function loadOptionsAsync(
  properties,
  ownEndpoint,
  id = undefined,
  mainGraphQlName=undefined,
  preloadedData = undefined
) {
  let extraData = [];
  const partitionedByEndpoint = {};
  properties.forEach((property) => {
    if(!!property.graphQlName) return;
    if(property.unique && !property.endpoint) {
      property.endpoint = ownEndpoint;
    }

    if (property.endpoint) {
      if (id) {
        property.endpoint = property.endpoint.replace("{id}", id);
      } else {
        property.endpoint = property.endpoint.replace("includeId={id}&", "");
      }

      if (!partitionedByEndpoint[property.endpoint]) {
        partitionedByEndpoint[property.endpoint] = [];
      }
      partitionedByEndpoint[property.endpoint].push(property);
    }
  });

  const fetch = async (endpoint, props, ownData) => {
    let data = extraData.filter((d) => d.endpoint === endpoint);
    // If own data is loaded it is not the simple type, but we don't also wish to load the simple endpoint then as we have everything.
    if(endpoint.replace('/simple', '') == ownEndpoint && !!ownData) {
      data = ownData;
    }
    else if (!data.length) {
      data = (await CrudService.getGridData(endpoint)).data.value;
      extraData.push({ endpoint, data });
    } else {
      data = data[0].data;
    }

    process(props, data, ownData);
  };

  const process = (props, data, ownData) => {
    props.forEach((property) => {
      property.usedValues = property.uniqueCombinations ? (ownData ?? data) : data;
      if (property.displayProperties?.length) {
        property.options = data.map((obj) => {
          let values = [];
          property.displayProperties.forEach((element) => {
            const split = element.split(".");
            let actualPath = obj;
            split.forEach((el) => {
              if (actualPath[el] === undefined || actualPath[el] === null)
                return;
              actualPath = actualPath[el];
            });
            values.push(actualPath);
          });
          return {
            displayText: values.join("-"),
            key: obj[property.keyProperty],
          };
        });
      }
    });
  };

  if(!preloadedData) {
    const tasks = [];
    let ownData = undefined;
    if (properties.filter((x) => x.uniqueCombinations).length) {
      ownData = (await CrudService.getGridData(ownEndpoint)).data.value;
    }

    for (const endpoint in partitionedByEndpoint) {
      tasks.push(
        fetch(endpoint, partitionedByEndpoint[endpoint], ownData)
      );
    }

    await Promise.all(tasks);
  }
  else {
    properties.forEach(prop => {
      if(!!prop.graphQlName) {
        process([prop], preloadedData[prop.graphQlName], preloadedData[mainGraphQlName])
      }
    });
  }
}

export function prepareData(data) {
  for (let property in data) {
    if (
      moment(data[property], "YYYY-MM-DDTHH:mm:ss", true).isValid() === true
    ) {
      data[property] = data[property].split("T")[0];
    }
  }
}

export function createUrl(
  config,
  data = undefined,
  requiredVariable = undefined
) {
  function getQueryValue(param, data, requiredVariable) {
    if (param.useRequiredVariable) return requiredVariable;
    if (param.variableName && data) {
      let obj = data;

      const split = param.variableName.split(".");
      for (let s of split) {
        obj = obj[s];
        if (!obj) return undefined;
      }

      return obj;
    }
    return param.value;
  }

    function getQueryUrl(config, data, requiredVariable) {
      var queryParameters = config.queryParameters.length
        ? config.queryParameters
            .map((param) => {
              const value = getQueryValue(param, data, requiredVariable);
    
              if (param.key === 'werkelijkeToolId' && !value) {
                return null; // Exclude this parameter entirely - cheaty way to resolve issue WT crud where null is allowed
              }
    
              return `${param.key}=${value ?? 'undefined'}`;
            })
            .filter((param) => param !== null) // Remove null parameter
            .join("&")
        : "";
      if (queryParameters.includes("undefined")) return undefined;
    
      return `${config.endpoint}${queryParameters !== "" ? "?" : ""}${queryParameters}`;
    }

    function getPathUrl(config, data) {
    let url = config.endpoint;
    const parameter = config.pathParameter;

    if (parameter) {
      let subObj = data;
      const params = parameter.split(".");
      for (let p of params) {
        subObj = subObj[p];
        if (!subObj) return undefined;
      }

      url += `/${subObj}`;
    }

    return url;
  }

  if (config.queryParameters) {
    return getQueryUrl(config, data, requiredVariable);
  } else {
    return getPathUrl(config, data);
  }
}
